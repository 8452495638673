import React from 'react'
import PropTypes from 'prop-types'
import LiveCourseIcon from 'Svgs/LiveCourseIcon'
import Link from 'next/link'
import NumberWithCommas from 'Utils/NumberWithCommas'

const commonClasses =
  'w-full text-base text-center tracking-wide font-semibold uppercase p-2 h-12 rounded-2xl mt-2'

const ButtonItem = (element) => (
  <Link href={element.urlHref} as={element.urlAs} passHref>
    <button
      type="button"
      className={`${commonClasses} ${
        element.secondary
          ? 'text-indigo-900 bg-gray-100 border border-solid border-indigo-900'
          : 'text-gray-100 bg-indigo-900 border border-solid border-indigo-900'
      }`}
    >
      {element.text}
    </button>
  </Link>
)

const LiveButtonItem = (element) => (
  <Link href={element.urlHref} as={element.urlAs} passHref>
    <button
      type="button"
      className={`${commonClasses} text-red-600 bg-red-200 animate-pulse border border-solid border-red-300`}
    >
      <>
        <LiveCourseIcon className="mr-2" />
        ONGOING LIVE CLASS
      </>
    </button>
  </Link>
)

function CourseCardButton({ myCourse, course }) {
  const urlHrefMyCourse = {
    pathname: '/course/[slug]/content',
    query: { slug: course.slug },
  }
  const urlAsMyCourse = `/course/${course.slug}/content`

  const urlHrefPublicCourse = {
    pathname: '/course/[slug]',
    query: { slug: course.slug },
  }
  const urlAsPublicCourse = `/course/${course.slug}`

  const urlHrefPaidCourseJoin = {
    pathname: '/course/[slug]',
    query: { slug: course.slug, join: true },
  }
  const urlAsPaidCourseJoin = `/course/${course.slug}?join=true`

  const urlHrefPaidCourseTrialJoin = {
    pathname: '/course/[slug]',
    query: { slug: course.slug, trialJoin: true },
  }
  const urlAsPaidCourseTrialJoin = `/course/${course.slug}?trialJoin=true`

  return (
    <div className="w-full">
      {myCourse && (
        <>
          {!course.isLive && (
            <ButtonItem
              urlHref={urlHrefMyCourse}
              urlAs={urlAsMyCourse}
              text="GO TO COURSE"
            />
          )}
          {course.isLive && (
            <LiveButtonItem urlHref={urlHrefMyCourse} urlAs={urlAsMyCourse} />
          )}
        </>
      )}
      {!myCourse && (
        <>
          {course.plan === 'public' && (
            <ButtonItem
              urlHref={urlHrefPublicCourse}
              urlAs={urlAsPublicCourse}
              text="JOIN FOR FREE"
            />
          )}
          {course.plan === 'private' && (
            <ButtonItem
              urlHref={urlHrefPublicCourse}
              urlAs={urlAsPublicCourse}
              text="VIEW"
            />
          )}
          {course.plan === 'paid' && !course.trialEnabled && (
            <ButtonItem
              urlHref={urlHrefPaidCourseJoin}
              urlAs={urlAsPaidCourseJoin}
              text={`Buy ₹${NumberWithCommas(course.price)}`}
            />
          )}
          {course.plan === 'paid' && course.trialEnabled && (
            <div className="inline-grid grid-cols-2 gap-4 w-full">
              <ButtonItem
                urlHref={urlHrefPaidCourseJoin}
                urlAs={urlAsPaidCourseJoin}
                text={`Buy ₹${NumberWithCommas(course.price)}`}
              />
              <ButtonItem
                urlHref={urlHrefPaidCourseTrialJoin}
                urlAs={urlAsPaidCourseTrialJoin}
                secondary
                text="FREE TRIAL"
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}

CourseCardButton.defaultProps = {
  myCourse: false,
}

CourseCardButton.propTypes = {
  myCourse: PropTypes.bool,
  course: PropTypes.object.isRequired,
}

export default CourseCardButton
