import React from 'react'
import RatingBlock from 'Components/RatingBlock'
import PersonIcon from '@material-ui/icons/Person'
import CourseCardButton from 'Components/CourseCardButton'
import ImageBlock from 'Components/ImageBlock'
import PropTypes from 'prop-types'
import Link from 'next/link'
import Typography from '@material-ui/core/Typography'

const thumbnailHeight = 300
const thumbnailWidth = 300

const PublicCourseCard = ({ course }) => {
  const urlHref = {
    pathname: '/course/[slug]',
    query: { slug: course.slug },
  }
  const urlAs = `/course/${course.slug}`
  return (
    <div className="inline-block">
      {course != null && (
        <>
          <div
            className="ylurnCourseCard relative cursor-pointer border border-gray-300 hover:shadow-xl shadow-lg rounded-lg"
            style={{
              width: `${thumbnailWidth}px`,
            }}
          >
            <Link href={urlHref} as={urlAs}>
              <a>
                <div
                  className="rounded-lg relative"
                  style={{
                    background:
                      'linear-gradient(180deg, rgba(16, 20, 45, 0) 59.38%, rgba(16, 20, 45, 0.2) 74.48%, rgba(16, 20, 45, 0.6) 100%)',
                    height: `${thumbnailHeight}px`,
                  }}
                >
                  <ImageBlock
                    width={thumbnailWidth}
                    height={thumbnailHeight}
                    alt={course.title}
                    className="object-cover w-full shadow-inner"
                    style={{
                      height: `${thumbnailHeight}px`,
                      mixBlendMode: 'multiply',
                      borderRadius: 'inherit',
                    }}
                    src={course.thumbnail}
                  />
                  <div className="mb-5 ml-4 mr-4 absolute bottom-0">
                    <Typography
                      className="text-white text-xl leading-6 font-bold overflow-hidden"
                      style={{
                        // select tailwind leading property properly
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        /* to specify the number of lines you want the text to run through... */
                        WebkitLineClamp: 2,
                        /* hide the overflowing text, i.e, texts that did not fit in to the box */
                      }}
                      variant="h6"
                    >
                      {course.title}
                    </Typography>
                  </div>
                </div>
              </a>
            </Link>
          </div>
          <div>
            <div className="w-full mt-2 my-2 h-4">
              {course.ratingData && course.ratingData.rating !== -1 && (
                <span className="inline-block align-middle mr-2">
                  <RatingBlock
                    size="small"
                    rating={course.ratingData.rating}
                    nRatings={course.ratingData.nRatings}
                  />
                </span>
              )}
              {course.studentCountVisible && course.nStudents > 0 && (
                <span className="inline-block align-middle">
                  <PersonIcon className="w-4 h-4 mr-1 align-middle" />
                  {course.nStudents}
                </span>
              )}
            </div>
            <div
              style={{
                width: `${thumbnailWidth}px`,
              }}
            >
              <CourseCardButton course={course} />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

PublicCourseCard.propTypes = {
  course: PropTypes.object.isRequired,
}

export default PublicCourseCard
